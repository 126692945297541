export const ORDER_STATUS = {
  SHIPPING: "shipping",
  SHIPPED: "shipped",
  DELIVERED: "delivered",
  RECEIVED: "received",
};

export const ORDER_STATUS_COLORS = {
  shipping: "info",
  shipped: "warning",
  delivered: "primary",
  received: "success",
};
